import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import Image from 'next/image';

import { cn } from "@/lib/utils";

const curveVariants = cva(
    "u-relative",
    {
        variants: {
            variant: {
                1: "",
                2: "",
                3: "",
                4: "",
                5: "",
                6: "u-pt-2.5 lg:u-pt-10",
                7: "u-bg-[#f6f6f6]",
                8: "",
                9: "",
                10: "",
            },
        },
        defaultVariants: {
            variant: 6,
        },
    }
)

export interface CurveProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof curveVariants> {
}

const Curve = ({ className, variant, children, ...props }: CurveProps) => {

    return (
        <div
            className={cn(curveVariants({ variant, className }))}
            {...props}
        >
            {variant && [1, 2, 3, 4, 5].includes(variant) && (
                <>
                    <Image
                        src={`/images/curves/curve-${variant}.png`}
                        alt="Green curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-h-auto"
                    />
                </>
            )}

            {variant === 6 && (
                <>
                    <Image
                        src="/images/curves/curve-6-a.svg"
                        alt="Green curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-h-auto"
                    />
                    <Image
                        src="/images/curves/curve-6-b.svg"
                        alt="Blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-h-auto u-absolute u-bottom-0 u-right-0"
                    />
                    <Image
                        src="/images/curves/curve-6-c.svg"
                        alt="Deep blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-h-auto u-absolute -u-bottom-px u-left-0"
                    />
                    <Image
                        src="/images/curves/curve-6-d.svg"
                        alt="thing curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-h-auto u-absolute u-top-0 u-right-0"
                    />
                </>
            )}

            {variant === 7 && (
                <>
                    <Image
                        src="/images/curves/curve-7-a.svg"
                        alt="Blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-44 md:u-w-80 lg:u-w-[710px] lg:-u-mt-4"
                    />
                    <Image
                        src="/images/curves/curve-7-b.svg"
                        alt="Gray curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-absolute -u-bottom-px u-left-0 md:-u-bottom-2"
                    />
                </>
            )}

            {variant === 8 && (
                <>
                    <Image
                        src="/images/curves/curve-8-a.svg"
                        alt="Blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-absolute u-object-cover u-object-right u-bottom-0"
                    />
                    <Image
                        src="/images/curves/curve-8-b.svg"
                        alt="Gray curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-absolute u-bottom-0 u-left-0"
                    />
                </>
            )}

            {variant === 9 && (
                <>
                    <Image
                        src="/images/curves/curve-9-a.svg"
                        alt="Blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-relative u-z-10 -u-bottom-1 "
                    />
                    <Image
                        src="/images/curves/curve-9-b.svg"
                        alt="Gray curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-1/2 u-absolute u-bottom-0 u-right-0"
                    />
                </>
            )}

            {variant === 10 && (
                <>
                    <Image
                        src="/images/curves/curve-10-a.svg"
                        alt="Blue curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-full u-relative u-z-10 u-top-2"
                    />
                    <Image
                        src="/images/curves/curve-10-b.svg"
                        alt="Gray curve"
                        sizes="100vw"
                        width={1920}
                        height={100}
                        className="u-w-11/12 u-absolute u-bottom-0 u-left-0"
                    />
                </>
            )}
        </div>
    )
}

export { Curve, curveVariants }
