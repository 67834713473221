"use client"

import { useRef, useState } from "react"

import { cn } from "@/lib/utils"
import { useClickOutside } from "@/hooks/useOnClickOutside"
import { Button } from "../Button"
import { Wizard, Step } from "../Wizard"
import ExclamationIcon from '../../../../public/images/icons/exclamation.svg'
import { beam } from "@/helpers/beam"

const validateZip = (zip: string) => {
  const zipRegex = new RegExp(/^\d{5}$/)
  return zipRegex.test(zip)
}

type StickyZipEntryProps = {
  /** callback to be called when the user clicks Find Plans button */
  onFindPlansClick?(URL: string): void
}

const StickyZipEntry = ({ onFindPlansClick }: StickyZipEntryProps) => {
  const [zipCode, setZipCode] = useState("")
  const [move, setMove] = useState("")
  const [apartment, setApartment] = useState("")
  let URL = `plans/?zipCode=${zipCode}&m=${move}`
  const [wasZipCodeFocused, setWasZipCodeFocused] = useState(false)
  const zipCodeRef = useRef(null)
  const [showZipCodeError, setShowZipCodeError] = useState(false)

  useClickOutside(zipCodeRef, () => setShowZipCodeError(false))

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "")
    setShowZipCodeError(false)
    setZipCode(value)
  }

  const handleZipCodeFocus = () => {
    if (wasZipCodeFocused) {
      return
    }

    setWasZipCodeFocused(true)

    const payload = {
      formContext: {
        formName: "Zip Search Form - Sticky Banner",
        formType: "Zip Check",
      },
    }
    beam('FormStarted', payload)
  }

  const handleFirstStepSubmit = (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
    e.preventDefault()
    const value = new FormData(e.currentTarget).get("zipCode") as string

    if (!validateZip(value)) {
      setShowZipCodeError(true)
      return
    }

    if (validateZip(value)) {
      setShowZipCodeError(false)

      const payload = {
        webElement: {
          location: value,
          position: 'zipEntry - Sticky Banner',
          elementType: 'cta Button',
          text: 'CONTINUE (Not Existing Customer)'
        },
        outboundUrl: 'shop.txu.com'
      }
      beam('ElementClicked', payload)

      if (callback && typeof callback === "function") {
        callback()
      }
    }
  }

  const handleSecondStepSubmit = (e: React.MouseEvent<HTMLInputElement>, callback: () => void, location: string, text: string, actionOutcome: string) => {
    setMove(e.currentTarget.value)

    const payload = {
        webElement: {
          location,
          elementType: 'CTA button',
          text
        },
        actionOutcome
      }
      beam('ElementClicked', payload)

      setTimeout(() => {
        callback()
      }, 500)

  }

  const handleThirdStepSubmit = (e: React.MouseEvent<HTMLInputElement>, location: string, text: string, actionOutcome: string) => {
    setApartment(e.currentTarget.value)
    URL += `&a=${e.currentTarget.value}`
    const payload = {
        webElement: {
          location,
          elementType: 'CTA button',
          text
        },
        actionOutcome
      }
      beam('ElementClicked', payload)

      if (onFindPlansClick && typeof onFindPlansClick === "function") {
        setTimeout(() => {
          onFindPlansClick(URL)
        }, 500)
      }
  }

  return (
    <Wizard
      data-cy="sticky-zip-entry"
    >
          {({ nextStep }) => (
            <>
              <Step
                className="u-flex u-flex-col u-items-center u-gap-4"
              >
                <form onSubmit={(e) => handleFirstStepSubmit(e, nextStep)} className="u-flex u-flex-col u-w-full u-gap-4 u-px-6 u-py-4" noValidate>
                  <div className={
                    cn(
                      "u-relative u-w-full u-flex u-items-center u-gap-3 u-border u-border-gray-300 u-rounded-full u-p-[0.188rem]",
                      "md:u-gap-4",
                      "md:u-bg-white md:u-rounded-full md:u-p-1",
                    )
                  }>
                    <div className="u-relative u-w-full">
                      <input
                        ref={zipCodeRef}
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        onFocus={handleZipCodeFocus}
                        id="zipCode"
                        name="zipCode"
                        type="tel"
                        pattern="[0-9]{5}"
                        required
                        maxLength={5}
                        placeholder="Enter ZIP code"
                        autoComplete="off"
                        className="u-py-3 u-px-4 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-text-gray-600 u-bg-no-repeat u-bg-[1rem_center] u-bg-map-marker u-bg-[length:0.625rem] md:u-outline-none focus:u-outline-none"
                        data-cy="zip-code-input"
                      />
                      <div
                        data-cy="zip-code-error"
                        className={
                          cn(
                            "u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden",
                            "u-absolute u-top-full u-items-center u-gap-2",
                            "before:u-content-[''] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4",
                            showZipCodeError && "u-flex"
                          )
                        }
                      >
                        <ExclamationIcon /> Please enter a valid ZIP Code
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      className="hover:u-bg-blue-500 focus:u-bg-blue-500 md:u-leading-6 md:u-px-7 md:u-py-4"
                      type="submit"
                      data-cy="zip-code-button"
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              </Step>
              <Step>
                <form
                  className="u-flex u-flex-col u-gap-3 u-w-full u-px-6 u-py-8 md:u-py-[2.375rem]"
                  noValidate
                >
                  <fieldset className="u-flex u-items-center u-justify-between u-gap-2 u-text-sm md:u-text-4.5 md:u-leading-6">
                    <h3 className="u-text-center">Are you moving to a new address?</h3>
                    <div className="u-group u-flex u-gap-2 u-float-right u-relative">
                      <label
                        data-cy="moving-radio-mYesLabel"
                        id="mYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-1 u-bg-white u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="movey"
                          name="m"
                          required
                          aria-labelledby="mYesLabel"
                          onClick={(e) => handleSecondStepSubmit(e, nextStep, 'Move/Switch', 'Yes', 'User indicates Yes they are moving')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="moving-radio-mNoLabel"
                        id="mNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-1 u-bg-white u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="moven"
                          name="m"
                          required
                          aria-labelledby="mNoLabel"
                          onClick={(e) => handleSecondStepSubmit(e, nextStep, 'Move/Switch', 'No', 'User indicates No they are not moving')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        No
                      </label>
                    </div>
                  </fieldset>
                </form>
              </Step>
              <Step>
                <form
                  className="u-flex u-flex-col u-gap-3 u-w-full u-px-6 u-py-8 md:u-py-[2.375rem]"
                  noValidate
                >
                  <fieldset className="u-flex u-items-center u-justify-between u-gap-2 u-text-sm md:u-text-4.5 md:u-leading-6">
                    <h3 className="u-text-center">Are you moving to an apartment?</h3>
                    <div className="u-group u-flex u-gap-2 u-float-right u-relative">
                      <label
                        data-cy="apartment-radio-aYesLabel"
                        id="aYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-1 u-bg-white u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmenty"
                          name="a"
                          required
                          aria-labelledby="aYesLabel"
                          onClick={(e) => handleThirdStepSubmit(e, 'Apt/Switch', 'Yes', 'User indicates Yes they are moving to an apartment')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="apartment-radio-aNoLabel"
                        id="aNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-1 u-bg-white u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmentn"
                          name="a"
                          required
                          aria-labelledby="aNoLabel"
                          onClick={(e) => handleThirdStepSubmit(e, 'Apt/Switch', 'No', 'User indicates No they are not moving to an apartment')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        No
                      </label>
                    </div>
                  </fieldset>
                </form>
              </Step>
            </>
          )}
        </Wizard>
  )
}

export default StickyZipEntry
