import ZipEntry from '@/components/design-system/ZipEntry'
import Image from 'next/image'
import React from 'react'
import { openExternalLink } from '@/helpers/setCookieAndLinkOff'
import { useMonarch } from '@redventures/cohesion-utils-react'

interface Hero {
  value: {
    heading: string
    subHeading: string
  }
}

interface Rules {
  homepageHeroCopy?: Hero
}

const HeroSection = () => {
  const { rules }: { rules: Rules } = useMonarch()
  const heroCopy = rules?.homepageHeroCopy?.value

  const handleZipCodeClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }
  return (
    <div className="u-flex u-flex-col u-gap-y-10 u-py-10 md:u-flex-row md:u-items-start md:u-gap-x-16 md:u-px-2 md:u-pb-10 md:u-pt-14 lg:u-mx-auto lg:u-max-w-[75rem] lg:u-justify-between lg:u-gap-x-0 lg:u-p-20 xl:u-box-content xl:u-w-full xl:u-justify-between 3xl:u-relative 3xl:u-z-10 3xl:u-pb-28 5xl:u-pb-52">
      <div className="u-flex u-w-full u-flex-col u-gap-y-4 u-px-6 md:u-w-96 md:u-gap-y-[1.625rem] lg:u-w-[38.5rem] lg:u-gap-y-10 lg:u-px-0">
        <header className="u-flex u-flex-col u-gap-y-6 u-tracking-tighter md:u-gap-y-4 lg:u-gap-y-6">
          <h1 className="t-title-xl md:t-title-2xl lg:t-title-5xl u-text-balance u-text-center u-tracking--tight u-text-blue-600 md:u-text-left md:u-leading-10 md:u-tracking--tighter lg:u-text-wrap lg:u-leading-[4.5rem]">
            {heroCopy?.heading || 'Plans and savings you can count on'}
          </h1>
          <span className="t-body-lg lg:t-title-base u-self-center u-text-center u-leading-[1.875rem] u-text-gray-600 md:u-w-full md:u-self-auto md:u-text-left lg:u-w-9/12">
            {heroCopy?.subHeading || 'Find plans for your home or apartment.'}
          </span>
        </header>
        <div className="lg:u-w-[31.625rem] lg:u-self-center xl:u-w-[36.5625rem] xl:u-self-start">
          <ZipEntry onFindPlansClick={handleZipCodeClick} />
        </div>
      </div>
      <div className="u-flex u-items-center u-justify-center">
        <Image
          src="/images/hero-option-1.jpg"
          alt="picture of a family smiling"
          className="u-w-full u-px-6 u-mix-blend-darken md:u-w-[17.4375rem] md:u-px-0 lg:u-w-[279px] xl:u-w-[481px]"
          width={481}
          height={517}
        />
      </div>
    </div>
  )
}

export default HeroSection
