"use client";

import React from "react";

import { cn } from "@/lib/utils";
import { useNavBarContext, useNavBarItemContext } from "./";

type NavBarTriggerProps = React.ComponentProps<"button">;

const NavBarTrigger = ({ children, ...props }: NavBarTriggerProps) => {
    const itemContext = useNavBarItemContext();
    const navBarContext = useNavBarContext();
    const isOpen = navBarContext.value === itemContext;

    const handleOnClick = () => {
        if (isOpen) {
            return navBarContext.onItemSelect("");
        }

        return navBarContext.onItemSelect(itemContext);
    }

    return (
        <button
            {...props}
            className="u-w-full u-flex u-justify-between u-items-center u-text-blue-600 u-font-900 u-text-4.5 u-leading-6 u-gap-2 u-pr-3 lg:u-pr-0"
            onClick={handleOnClick}
            aria-expanded={isOpen}
            data-cy="navbar-trigger"
        >
            {children}

            <img src="/images/icons/caret-down-blue.svg" alt="Caret Down" className={cn("u-duration-300 lg:u-w-2", isOpen && "u-rotate-180 transform-gpu")} />
        </button>
    );
};

export default NavBarTrigger;
