"use client";

import React from 'react';

import { cn } from '@/lib/utils';
import { useNavBarContext } from '.';

type NavBarListProps = React.HTMLAttributes<HTMLUListElement> & {
    onToggle?: (isOpen: boolean) => void;
    children?: React.ReactNode;
}

const NavBarList = ({ onToggle, ...props }: NavBarListProps) => {
    const { setIsRootExpanded, onItemSelect, isRootExpanded } = useNavBarContext();
    const ref = React.useRef(null);

    const handleToggle = () => {
        setIsRootExpanded(!isRootExpanded);
        onItemSelect("");
    };

    return (
        <>
            <button
                data-cy="navbar-btn"
                className="u-flex u-flex-col u-h-8 u-aspect-square u-gap-1.5 u-justify-center lg:u-hidden"
                onClick={handleToggle}
                aria-expanded={isRootExpanded}
            >
                <span className={
                    cn(
                        "u-bg-blue-500 u-h-0.5 u-w-8 u-duration-300",
                        isRootExpanded && "u--rotate-45 u-translate-y-2 u-translate-x-0 transform-gpu"
                    )
                }></span>
                <span className={
                    cn(
                        "u-bg-blue-500 u-h-0.5 u-w-8",
                        isRootExpanded && "u-opacity-0"
                    )
                }></span>
                <span className={
                    cn(
                        "u-bg-blue-500 u-h-0.5 u-w-8 u-duration-300",
                        isRootExpanded && "u-rotate-45 u--translate-y-2 u--translate-x-0 transform-gpu"
                    )
                }></span>
                <span className="u-sr-only">Menu</span>
            </button>
            <ul
                ref={ref}
                data-cy="navbar-list"
                {...props}
                className={
                    cn(
                        "u-flex u-flex-col u-px-4 u-fixed u-bg-white u-top-[4.7rem] u-left-0 u-bottom-0 u-w-full lg:u-static lg:u-flex lg:u-flex-row lg:u-gap-10 lg:u-w-auto lg:u-border-0",
                        !isRootExpanded && "u-hidden",
                        isRootExpanded && "u-border-t u-border-t-blue-300 u-border-opacity-20"
                    )
                }
            />
        </>
    );
};

export default NavBarList;
