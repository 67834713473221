'use client'

import React, { useRef, useState, useEffect, TouchEvent } from 'react'
import CustomIcon from "@/components/design-system/CustomIcon"

type Breakpoint = {
  breakpoint: { min: number; max: number }
  items: number
  slidesToSlide: number
  itemWidth?: string | number
  partialVisibility?: number
}

export type ResponsiveConfig = Record<string, Breakpoint>

interface CarouselProps {
  children: React.ReactNode
  responsive: ResponsiveConfig
}

const Carousel: React.FC<CarouselProps> = ({ children, responsive }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [visibleItems, setVisibleItems] = useState(1)
  const [slidesToSlide, setSlidesToSlide] = useState(1)
  const [itemWidth, setItemWidth] = useState<number | null>(null)
  const [partialVisibility, setPartialVisibility] = useState<number>(0)
  const [containerWidth, setContainerWidth] = useState<string>('0px')
  const trackRef = useRef<HTMLDivElement>(null)

  const touchStartX = useRef(0)
  const touchEndX = useRef(0)

  const updateResponsiveSettings = () => {
    const width = window.innerWidth
    for (const key in responsive) {
      const {
        breakpoint: { min, max },
        items,
        slidesToSlide,
        itemWidth,
        partialVisibility = 0,
      } = responsive[key]

      if (width >= min && width <= max) {
        setVisibleItems(items)
        setSlidesToSlide(slidesToSlide)
        setPartialVisibility(partialVisibility)

        const widthPerItem =
          typeof itemWidth === 'number' ? itemWidth : parseFloat(itemWidth || '0')
        setItemWidth(widthPerItem)

        const totalWidth = widthPerItem * items + widthPerItem * partialVisibility
        setContainerWidth(`${totalWidth}px`)
        break
      }
    }
  }

  useEffect(() => {
    updateResponsiveSettings()
    window.addEventListener('resize', updateResponsiveSettings)
    return () => window.removeEventListener('resize', updateResponsiveSettings)
  }, [])

  const getTranslatePosition = (index: number) => {
    if (!trackRef.current || itemWidth === null) return 0
    return index * itemWidth
  }

  const handleNext = () => {
    const totalChildren = React.Children.count(children)
    const maxIndex = totalChildren - visibleItems

    if (currentIndex < maxIndex) {
      const newIndex = Math.min(currentIndex + slidesToSlide, maxIndex)
      setCurrentIndex(newIndex)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      const newIndex = Math.max(currentIndex - slidesToSlide, 0)
      setCurrentIndex(newIndex)
    }
  }

  const handleDotClick = (index: number) => {
    const newIndex = index * slidesToSlide
    setCurrentIndex(newIndex)
  }

  const handleTouchStart = (event: TouchEvent) => {
    touchStartX.current = event.touches[0].clientX
  }

  const handleTouchMove = (event: TouchEvent) => {
    touchEndX.current = event.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const swipeDistance = touchStartX.current - touchEndX.current
    const threshold = 50

    if (swipeDistance > threshold) {
      handleNext()
    } else if (swipeDistance < -threshold) {
      handlePrev()
    }
  }

  const trackStyle = {
    transform: `translateX(-${getTranslatePosition(currentIndex)}px)`,
    width: containerWidth,
  }

  const totalChildren = React.Children.count(children)
  const totalDots = Math.ceil((totalChildren - visibleItems) / slidesToSlide) + 1

  if (containerWidth === '0px') {
    return null
  }

  return (
    <div
      className="u-relative u-flex u-items-center u-py-4 u-pb-8 u-z-[20]"
      style={{ width: containerWidth }}
      data-cy="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <button
        className="u-absolute u-hidden lg:u-block u-left-[-5.625rem] u-z-[-1] u-bg-transparent u-border-none u-text-2xl u-cursor-pointer u-select-none"
        onClick={handlePrev}
        aria-label="Previous Slide"
        data-cy="carousel-prev-button"
      >
        <CustomIcon icon="arrowLeft" additionalClasses="u-h-[120px] u-w-[120px] custom-left-arrow" />
      </button>

      <div className="u-overflow-hidden u-flex-1" data-cy="carousel-track-container">
        <div
          className="u-flex u-transition-transform u-duration-300 u-ease-in-out u-py-4 u-pl-2 md:u-pl-0"
          style={trackStyle}
          ref={trackRef}
          data-cy="carousel-track"
        >
          {React.Children.map(children, (child, index) => (
            <div
              className="u-flex-shrink-0 u-box-border u-flex u-items-center u-justify-center"
              style={{ width: `${itemWidth}px` }}
              data-cy={`carousel-item-${index}`}
            >
              {child}
            </div>
          ))}
        </div>
      </div>

      <button
        className="u-absolute u-hidden lg:u-block u-right-[-4.562rem] u-z-[-1] u-bg-transparent u-border-none u-text-2xl u-cursor-pointer u-select-none"
        onClick={handleNext}
        aria-label="Next Slide"
        data-cy="carousel-next-button"
      >
        <CustomIcon icon="arrowRight" additionalClasses="u-h-[7.5rem] u-w-[7.5rem] custom-left-arrow" />
      </button>

      <div
        className="u-absolute u-bottom-0 u-left-0 u-right-0 u-m-auto u-flex u-gap-2 u-w-max u-items-center"
        data-cy="carousel-dots"
      >
        {Array.from({ length: totalDots })
        .splice(0, 3)
        .map((_, index) => {
          return (
            <button
              key={index}
              className={`u-h-[0.625rem] u-w-[0.625rem] u-rounded-full u-transition-all u-cursor-pointer ${
                index === Math.floor(currentIndex / slidesToSlide) || (index === 2 && currentIndex >= 3)
                  ? 'u-w-[0.875rem] u-h-[0.875rem] u-bg-blue-500'
                  : 'u-bg-gray-400'
              }`}
              onClick={() => handleDotClick(index)}
              aria-label={`Go to slide ${index + 1}`}
              data-cy={`carousel-dot-${index}`}
            ></button>
          )
        })}
      </div>
    </div>
  )
}

export default Carousel
