"use client";

import React, { createContext, useContext, useId } from "react";

import { cn } from "@/lib/utils";

export const NavbarItemContext = createContext<string | undefined>(undefined);

export const useNavBarItemContext = () => {
    const context = useContext(NavbarItemContext);

    if (!context) {
        throw new Error("useNavBarItemContext must be used within a NavbarItemContext Provider");
    }

    return context;
}

type NavbarItemProps = React.HTMLAttributes<HTMLLIElement>;

const NavBarItem = ({ className, ...props }: NavbarItemProps) => {
    const id = useId();

    return (
        <NavbarItemContext.Provider value={id}>
            <li
                data-cy="navbar-item"
                {...props}
                className={
                    cn(
                        "u-flex u-flex-col u-items-start u-py-7 u-border-b u-border-gray-500 u-relative lg:u-flex-row lg:u-justify-between lg:u-border-none",
                        className
                    )
                }
            />
        </NavbarItemContext.Provider>
    );
};

export default NavBarItem;
