import React from "react";

import { cn } from "@/lib/utils";

type NavBarLinkExternalProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const NavBarLinkExternal = ({ className, ...props }: NavBarLinkExternalProps) => {
    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            data-cy="navbar-link-external"
            {...props}
            className={
                cn(
                    "u-font-900 u-text-blue-600 u-text-4.5 u-leading-6",
                    className
                )
            }
        />
    );
};

export default NavBarLinkExternal;
