import CustomIcon from '../design-system/CustomIcon'
import ZipEntry from '../design-system/ZipEntry'
import { openExternalLink } from '@/helpers/setCookieAndLinkOff'

const GeoSection = () => {
  const handleZipCodeClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }

  return (
    <section className="u-flex u-flex-col u-items-center u-gap-6 u-px-6 u-py-12 lg:u-gap-14 lg:u-px-32 lg:u-py-20">
      <div className="u-flex u-flex-col u-items-center u-gap-2">
        <h2 className="u-text-balance u-text-center u-text-7 u-font-900 u-text-blue-600 lg:u-text-[3.5rem] lg:u-leading-[3.875rem]">
          Electricity wherever you need it
        </h2>
        <p className="u-text-4.5 u-font-400 u-leading-[1.875rem] u-text-gray-700 lg:u-text-xl lg:u-leading-9">
          Shop, sign up, save.
        </p>
      </div>
      <div className="u-w-full md:u-max-w-80 lg:u-max-w-[31.625rem]">
        <ZipEntry onFindPlansClick={handleZipCodeClick} variant="geo" />
      </div>

      <div className="u-flex u-flex-col u-items-center u-gap-10 lg:u-max-w-full">
        <h3 className="u-text-center u-text-6 u-font-900 u-leading-[1.875rem] u-text-blue-600 lg:u-text-8 lg:u-leading-[2.375rem]">
          Or, see our plans in these cities and more:
        </h3>
        <ul className="u-mx-auto u-w-full u-max-w-[16.5rem] u-columns-2 md:u-max-w-full md:u-columns-3 lg:u-flex lg:u-max-w-none lg:u-columns-1 lg:u-justify-center lg:u-gap-10">
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/houston/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Houston
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/dallas/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Dallas
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/fort-worth/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Fort Worth
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/killeen/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Killeen
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/odessa/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Odessa
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
          <li className="u-py-4 lg:u-py-0">
            <a
              href="/lubbock/"
              className="u-flex u-items-center u-justify-start u-gap-2 u-text-4 u-font-900 u-text-blue-500 u-no-underline"
            >
              Lubbock
              <CustomIcon icon="arrowRightThin" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default GeoSection
