"use client";

import { useRef } from "react";

import { useClickOutside } from "@/hooks/useOnClickOutside";
import { useNavBarContext } from "./";

type NavBarWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
}

const NavBarWrapper = ({ children }: NavBarWrapperProps) => {
    const ref = useRef(null);
    const { setIsRootExpanded, onItemSelect, isRootExpanded, value } = useNavBarContext();

    const handleOnClickOutside = () => {
        if (isRootExpanded || value) {
            setIsRootExpanded(false);
            onItemSelect("");
        }
    };

    useClickOutside(ref, handleOnClickOutside);

    return (
        <div
            ref={ref}
        >
            {children}
        </div>
    );
};

export default NavBarWrapper;