'use client'

import { useState, useEffect } from 'react'
import Headroom, { HeadroomOptions } from 'headroom.js'

import Header from '@/components/design-system/Header'
import {
  NavBar,
  NavBarContent,
  NavBarItem,
  NavBarLink,
  NavBarLinkExternal,
  NavBarList,
  NavBarTrigger,
} from '@/components/design-system/NavBar'
import CustomIcon from '@/components/design-system/CustomIcon'
import StickyZipEntry from '../design-system/StickyZipEntry'
import HeaderLogo from '../design-system/Header/HeaderLogo'
import { openExternalLink } from '@/helpers/setCookieAndLinkOff'
import { beam } from '@/helpers/beam'

const HeaderSection = () => {
  const [showStickyZip, setShowStickyZip] = useState(false)

  const handleOnUnpin = () => {
    setShowStickyZip(true)
  }

  const handleOnTop = () => {
    setShowStickyZip(false)
  }

  const handleOnFindPlansClick = (URL: string) => {
    openExternalLink(`/${URL}`)
  }

  useEffect(() => {
    const header = document.querySelector('header')

    if (header) {
      const headroom = new Headroom(header, {
        onUnpin: handleOnUnpin,
        onTop: handleOnTop,
        offset: 100,
      } as HeadroomOptions)

      if (headroom) {
        headroom.init()
      }
    }
  }, [])

  return (
    <>
      <Header className="headroom">
        <NavBar>
          <NavBarList>
            <NavBarItem>
              <NavBarTrigger>Plans</NavBarTrigger>
              <NavBarContent>
                <ul className="u-flex u-w-full u-flex-col u-gap-4">
                  <li className="u-text-base u-font-400 u-leading-8 u-text-gray-600 hover:u-text-blue-500">
                    <a href="/ultimate-season-pass/">Ultimate Season Pass</a>
                  </li>
                  <li className="u-text-base u-font-400 u-leading-8 u-text-gray-600 hover:u-text-blue-500">
                    <a href="/free-nights-and-solar-days/">
                      Free Nights and Solar Days
                    </a>
                  </li>
                  <li className="u-text-base u-font-400 u-leading-8 u-text-gray-600 hover:u-text-blue-500">
                    <a href="/solar-saver/">Solar Saver</a>
                  </li>
                </ul>
              </NavBarContent>
            </NavBarItem>
            <NavBarItem>
              <NavBarLink href="/why-txu/">Why TXU Energy</NavBarLink>
            </NavBarItem>
            <NavBarItem>
              <NavBarLink href="/moving-to-texas/">Moving To Texas</NavBarLink>
            </NavBarItem>
            <NavBarItem>
              <NavBarLinkExternal href="https://www.txu.com/business-electricity/small-business/request-quote?utm_medium=RV_cpc">
                <span className="u-text-blue-600">Business</span>
              </NavBarLinkExternal>
            </NavBarItem>
            <NavBarItem>
              <NavBarLink
                href="https://www.txu.com/signin"
                onClick={() => {
                  const payload = {
                    webElement: {
                      location: 'NAVBAR',
                      elementType: 'signInLink',
                      text: 'Sign In',
                    },
                  }
                  beam('ElementClicked', payload)
                }}
              >
                <span className="u-flex u-items-center u-gap-2 u-font-400 u-text-blue-600">
                  <CustomIcon
                    icon="signInBlue"
                    additionalClasses=""
                    aria-hidden="true"
                    data-cy="signInBlue-icon"
                  />
                  MyAccount
                </span>
              </NavBarLink>
            </NavBarItem>
          </NavBarList>
        </NavBar>
      </Header>
      {showStickyZip && (
        <div className="u-sticky u-left-0 u-top-0 u-z-50 u-w-full u-bg-white md:u-flex md:u-justify-between">
          <div className="u-hidden u-px-6 md:u-flex md:u-items-center lg:u-px-14">
            <HeaderLogo />
          </div>
          <StickyZipEntry onFindPlansClick={handleOnFindPlansClick} />
        </div>
      )}
    </>
  )
}

export default HeaderSection
