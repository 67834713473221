import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export type ValuesHeaderProps = HTMLAttributes<HTMLDivElement>;

const ValuesHeader = ({ className, ...props}: ValuesHeaderProps) => {
    return (
        <div
            data-cy="values-header"
            {...props}
            className={
                cn(
                    "u-text-balance u-text-white u-text-center",
                    "[&_h2]:u-text-7 [&_h2]:u-font-900 [&_h2]:u-text-white [&_h2]:u-text-center [&_h2]:u-text-balance",
                    "lg:[&_h2]:u-text-[3.5rem] lg:[&_h2]:u-text-left lg:[&_h2]:u-pr-[5.625rem]",
                    "[&_p]:u-text-4.5 [&_p]:u-font-400 [&_p]:u-text-white [&_p]:u-text-center [&_p]:u-text-balance [&_p]:u-mt-2",
                    "lg:[&_p]:u-text-[1.5rem] lg:[&_p]:u-text-left lg:[&_p]:u-mt-4",
                    className
                )
            }
        />
    );
};

export { ValuesHeader };
