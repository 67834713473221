import { ValuePropCard } from '@/components/design-system/ValuePropCard'
import React from 'react'

interface PromoPlan {
  title: string
  description: string
  image: string
  href: string
  visible?: boolean
  order?: number
}

const promoPlans: PromoPlan[] = [
  {
    title: 'Season Pass',
    description:
      'Save 50% all winter and summer long – with a reduced energy charge the months you’re using the most electricity.',
    image: 'ultimate-summer-pass-image',
    href: '/season-pass/',
    order: 1,
    visible: false,
  },
  {
    title: 'Ultimate Season Pass',
    description:
      'Save 50% during peak winter and summer and now get even more months of automatic savings.',
    image: 'ultimate-summer-pass-image',
    href: '/ultimate-season-pass/',
    order: 1,
    visible: true,
  },
  {
    title: 'Free Nights & Solar Days',
    description:
      'Sleep cool and comfortable with 100% free electricity at night and enjoy 100% solar during the day.',
    image: 'free-nights-image',
    href: '/free-nights-and-solar-days/',
    order: 2,
    visible: true,
  },
  {
    title: 'Solar Saver',
    description:
      'Help keep Texas skies cleaner with energy from local solar farms.',
    image: 'texas-choice-image',
    href: '/solar-saver/',
    order: 3,
    visible: true,
  },
]

const PromoSection = () => {
  return (
    <section className="u-flex u-flex-col u-gap-y-7 u-bg-gray-150 u-px-6 u-py-10 lg:u-pb-20 lg:u-pt-16 xl:u-gap-y-10">
      <div className="u-flex u-flex-col u-items-center xl:u-gap-y-2.5">
        <h2 className="t-title-lg md:t-title-xl u-text-blue-600 lg:u-text-[3.5rem]">
          Popular Plans
        </h2>
        <p className="t-longform-lg xl:t-longform-xl tracking-normal u-text-center u-text-gray-700">
          Here are a few of the plans Texans are choosing this season to help
          them save all year long.
        </p>
      </div>
      <div className="u-flex u-flex-col u-items-center u-gap-y-5 md:u-flex-row md:u-flex-wrap md:u-items-stretch md:u-justify-center md:u-gap-x-5 lg:u-gap-x-6">
        {promoPlans
          .filter((plan) => plan.visible)
          .map((plan) => (
            <ValuePropCard
              key={plan.title}
              href={plan.href}
              ctaText="Learn More"
              showButton={false}
              image={plan.image}
              title={plan.title}
              description={plan.description}
              handleClick={() => {}}
            />
          ))}
      </div>
    </section>
  )
}

export default PromoSection
