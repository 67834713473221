"use client";

import React, { useState } from "react";

import { cn } from "@/lib/utils";
import { NavBarContextProvider } from "./NavBarContextProvider";
import NavBarWrapper from "./NavBarWrapper";

export type NavBarProps = React.HTMLAttributes<HTMLDivElement>;

const NavBar = ({ className, ...props }: NavBarProps) => {
    const [selectedItem, setSelectedItem] = useState<string>("");
    const [isRootExpanded, setIsRootExpanded] = useState<boolean>(false);

    return (
        <NavBarContextProvider
            value={selectedItem}
            onItemSelect={setSelectedItem}
            isRootExpanded={isRootExpanded}
            setIsRootExpanded={setIsRootExpanded}
        >
            <NavBarWrapper>
                <nav
                    role="navigation"
                    aria-label="Main menu"
                    data-cy="navbar"
                    {...props}
                    className={
                        cn(
                            "u-flex u-justify-between u-items-center",
                            className
                        )
                    }
                />

            </NavBarWrapper>
        </NavBarContextProvider>
    );
};

export default NavBar;
