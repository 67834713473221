import GoogleReviewCarousel from '../layout/GoogleReviewCarousel'
import StarRating from '@/components/design-system/rating/StarRating'
import React from 'react'

const ReviewsSection = () => {
  return (
    <section className="u-relative u-py-10 u-text-blue-600 md:-u-mt-1 md:u-h-auto lg:u-h-[728px] lg:u-px-10 lg:u-py-20 3xl:u-pb-20">
      <div className="md:c-container u-relative">
        <div className="u-col-span-full u-grid u-gap-4 u-text-center lg:u-gap-6">
          <h2 className="t-title-lg md:t-title-lg lg:t-title-3-5xl">
            See what our customers are saying
          </h2>
          <div className="u-flex u-flex-col u-items-center u-justify-center u-gap-2 md:u-flex-row">
            <h4 className="t-body-base-bold u-hidden md:u-block">
              Most trusted by Texans {''}
            </h4>
            <div className="u-hidden u-h-1.5 u-w-1.5 u-rounded-full u-bg-blue-600 lg:u-block"></div>
            <StarRating rating={4.7} />
          </div>
        </div>
      </div>
      <GoogleReviewCarousel />
    </section>
  )
}

export default ReviewsSection
