import CustomIcon, { IconKeys } from "./CustomIcon";

export type GoogleReviewCardProps = {
  starsCount: number;
  text: string;
  name: string;
  reviewIcon: IconKeys;
  reviewProvider: string;
};

export function GoogleReviewCard({ text, name, starsCount = 0, reviewIcon, reviewProvider }: GoogleReviewCardProps) {
  const starsArray: string[] = Array(5).fill("Empty").fill("Full", 0, starsCount);

  return (
    <div className="google-review-card" aria-label={`Review by ${name}`} data-cy="google-review-card">
      <div className="google-review-card__stars" aria-label={`${starsCount} out of 5 stars`} data-cy="google-review-stars">
        {starsArray.map((element, index) => (
          <CustomIcon
            key={index}
            icon={`star${element}` as IconKeys}
            name={`star${element}`}
            additionalClasses="c-star"
            aria-hidden="true"
            data-cy={`star-${index}`}
          />
        ))}
      </div>
      <p className="google-review-card__text" aria-live="polite" data-cy="google-review-text">
        {text}
      </p>
      <footer className="google-review-card__footer" data-cy="google-review-footer">
        <CustomIcon icon="quote" additionalClasses="quote" aria-hidden="true" data-cy="quote-icon" />
        <div className="google-review-card__footer-info" data-cy="google-review-footer-info">
          <div className="google-review-card__footer-reviewer" aria-label="Reviewer" data-cy="reviewer-name">
            {name}
          </div>
          <div
            className="google-review-card__footer-review"
            role="contentinfo"
            aria-label={`Review source: ${reviewProvider}`}
            data-cy="review-source"
          >
            <CustomIcon icon={reviewIcon} additionalClasses="review-icon" aria-hidden="true" data-cy="review-icon" />
            <div className="google-review-card__footer-review-provider" data-cy="review-provider">
              {reviewProvider}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
