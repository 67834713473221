"use client";

import React from "react";

import { cn } from "@/lib/utils";
import { useNavBarContext, useNavBarItemContext } from "./";

type NavBarContentProps = React.HTMLAttributes<HTMLDivElement>;

const NavBarContent = ({ className, ...props }: NavBarContentProps) => {
    const itemContext = useNavBarItemContext();
    const navBarContext = useNavBarContext();
    const isOpen = navBarContext.value === itemContext;

    return (
        <div
            {...props}
            className={
                cn(
                    "u-flex u-justify-between u-items-center u-pt-5 u-w-full u-bg-white lg:u-absolute lg:u-z-10 lg:u-top-full lg:u-min-w-72 lg:u-w-max lg:u-py-6 lg:u-px-8 lg:u-rounded lg:u-shadow-e1",
                    className,
                    !isOpen && "u-hidden"
                )
            }
        />
    );
};

export default NavBarContent;
