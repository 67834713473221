import React from "react";

import HeaderLogo from "./HeaderLogo";
import { cn } from "@/lib/utils";

export type HeaderProps = React.HTMLAttributes<HTMLDivElement>;

const Header = ({ children, className, ...props}: HeaderProps) => {
    return (
        <header
            data-cy="header"
            {...props}
            className={
                cn(
                    "u-flex u-justify-between u-items-center u-bg-white u-sticky u-top-0 u-z-30 u-py-5 u-px-6 lg:u-px-14 lg:u-py-2.5 lg:u-py-2",
                    className
                )
            }
        >
            <HeaderLogo />
            {children}
        </header>
    );
};

export default Header;
