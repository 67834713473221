import React from "react";
import Link, { LinkProps } from 'next/link';

interface NavBarLinkProps extends LinkProps {
    children: React.ReactNode;
};

const NavBarLink = (props: NavBarLinkProps) => {
    return <Link {...props} className="u-font-900 u-text-blue-600 u-text-4.5 u-leading-6 u-cursor-pointer" />;
};

export default NavBarLink;
