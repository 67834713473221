import React from 'react'
import { GoogleReviewCard } from '../design-system/GoogleReviewCard'
import Carousel, { ResponsiveConfig } from '@/components/design-system/Carousel'
import { IconKeys } from '../design-system/CustomIcon'
import { useMonarch } from '@redventures/cohesion-utils-react'

const responsive = {
  desktop: {
    breakpoint: { max: 30000, min: 1260 },
    items: 3,
    slidesToSlide: 1,
    itemWidth: '380px',
  },
  smallDesktop: {
    breakpoint: { max: 1259, min: 1024 },
    items: 3,
    slidesToSlide: 1,
    itemWidth: '314px',
  },
  tablet: {
    breakpoint: { max: 1023, min: 641 },
    items: 2,
    slidesToSlide: 1,
    itemWidth: '375px',
  },
  mobile: {
    breakpoint: { max: 640, min: 420 },
    items: 1,
    itemWidth: '350px',
    slidesToSlide: 1,
    partialVisibility: 0.2,
  },
  sm: {
    breakpoint: { max: 419, min: 321 },
    items: 1,
    itemWidth: '350px',
    slidesToSlide: 1,
    partialVisibility: 0.06,
  },
  xs: {
    breakpoint: { max: 320, min: 0 },
    items: 1,
    itemWidth: '294px',
    slidesToSlide: 1,
    partialVisibility: 0.2,
  },
} as ResponsiveConfig

const reviews = [
  {
    key: 1,
    starsCount: 5,
    text: 'They made it super easy to sign up for services for my move. I gave them the details of my everyday lifestyle and they recommended the best plan at a low cost that aligns with what I’m looking for.',
    name: 'Ranada',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
  {
    key: 2,
    starsCount: 5,
    text: 'They made switching easy- answered all of my questions to help me find the plan that worked best for me and my solar set up.',
    name: 'John',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
  {
    key: 3,
    starsCount: 5,
    text: 'I’ve been with TXU since 2009 and they have the absolute best rates for me. I love the plan that I’m on, “Free Nights & Solar Days” (free usage from 8pm-5am). I am at work during the day so this plan works out perfectly for me.',
    name: 'G',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
  {
    key: 4,
    starsCount: 5,
    text: 'They answered all my questions and helped me switch to the best plan for our situation.',
    name: 'Jim',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
  {
    key: 5,
    starsCount: 5,
    text: 'Easy and quick, and in about 5 minutes we were on the new plan.',
    name: 'Jon',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
  {
    key: 6,
    starsCount: 5,
    text: 'The agent was very helpful today helping me lower my energy bill. She found me a new plan that better suited my lifestyle and also helped set up alerts.',
    name: 'Robert',
    reviewIcon: 'google' as IconKeys,
    reviewProvider: 'Google Review',
  },
]

interface Reviews {
  reviewText: string
  reviewerName: string
  stars: number
}

interface GoogleReviews {
  value: {
    data: {
      reviews: Reviews[]
    }
  }
}

interface Rules {
  googleReviews?: GoogleReviews
}

const GoogleReviewCarousel = () => {
  const { rules }: { rules: Rules } = useMonarch()
  const reviewsData = rules?.googleReviews?.value?.data?.reviews
  const reviewsArray =
    reviewsData?.map((review, index) => ({
      key: index + 1,
      starsCount: review.stars,
      text: review.reviewText,
      name: review.reviewerName,
      reviewIcon: 'google' as IconKeys,
      reviewProvider: 'Google Review',
    })) || reviews

  return (
    <div className="u-relative u-flex u-w-full u-flex-col u-items-center u-justify-center">
      <Carousel responsive={responsive}>
        {reviewsArray.map((review, index) => (
          <div
            className="u-h-[452px] u-w-[240px] xs:u-h-[400px] xs:u-w-[321px] xsp:u-h-[21.75rem] xsp:u-w-[20rem] md:u-h-[420px] md:u-w-[314px] lg:u-h-[360px] lg:u-w-[350px]"
            key={index}
          >
            <GoogleReviewCard
              starsCount={review.starsCount}
              text={review.text}
              name={review.name}
              reviewIcon={review.reviewIcon}
              reviewProvider={review.reviewProvider}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default GoogleReviewCarousel
