import React from 'react'
import Image from 'next/image';
import { cn } from '@/lib/utils'

export type ValuePropCardProps = {
  showButton: boolean;
  title: string;
  ctaText: string;
  description: string;
  image: string;
  href: string;
  titleClass?: string;
  handleClick: (e: React.MouseEvent) => void;
  descriptionClass?: string;
}

export function ValuePropCard({ctaText, description, descriptionClass, href, image, showButton, title, handleClick, titleClass = '' }: ValuePropCardProps) {
  return (
    <div className="value-prop-card" role="region" aria-labelledby={`${title}-header`} data-cy="value-prop-card">
      <div className="value-prop-card__icon" data-cy="value-prop-card-icon">
        <Image
          src={`/images/${image}.jpg`}
          className="u-rounded-sm u-w-full  md:u-w-[18.25rem]  lg:u-w-[17.5rem] lg:u-h-[9.875rem]"
          alt={ctaText}
          width={280}
          height={158}
        />
      </div>
      <h3 id={`${title}-header`} className={`value-prop-card__title ${titleClass}`}
          data-cy="value-prop-card-title">{title}</h3>
      <p className={cn("value-prop-card__description", descriptionClass)} data-cy="value-prop-card-description">{description}</p>
      <a
        href={`${href}`}
        onClick={handleClick}
        data-click-tracker
        data-name={title}
        data-text={ctaText}
        className="value-prop-card__CTA"
        data-location="CARDS"
        data-element-type="LINK"
        data-action-outcome="EXTERNALLINK"
        aria-label={`Learn more about ${title}`}
        data-outcome-url={href}
        data-cy="value-prop-card-link"
      >
        {ctaText} <span className='t-icon' aria-hidden="true">{'arrow_right_alt'}</span>
      </a>
    </div>
  )
}
