import {
  Values,
  ValuesHeader,
  ValuesContent,
  ValuesItem,
} from '@/components/design-system/Values'
import { Curve } from '@/components/design-system/Curve'

const ValuesSection = () => {
  return (
    <>
      <Curve variant={9} className="u-bg-gray-150" />
      <Values>
        <ValuesHeader>
          <h2>Why we&apos;re the #1 choice in Texas</h2>
          <p>Check out who we are, what we do and how we aim to help you.</p>
        </ValuesHeader>
        <ValuesContent className="md:u-gap-x-6">
          <ValuesItem className="md:u-w-[10.06rem] lg:u-w-[unset]">
            <img
              src="./images/icons/price-protect-icon.svg"
              alt="Price Protect Promise icon"
            />
            <h3 className="u-w-8/12 md:u-w-6/12 lg:u-w-[unset]">
              Price Protect Promise
            </h3>
            <p className="u-w-8/12 md:u-w-7/12 lg:u-w-[unset]">
              Your rate&apos;s protected from rising power costs, so you can
              rest easy all year long.*
            </p>
          </ValuesItem>
          <ValuesItem className="md:u-w-[15.31rem] lg:u-w-[unset]">
            <img
              src="./images/icons/custom/satisfaction-icon.svg"
              alt="Satisfction icon"
            />
            <h3>Total Satisfaction Guarantee</h3>
            <p className="u-w-9/12 md:u-w-10/12 lg:u-w-[unset] lg:u-text-pretty">
              Try us for 60 days with no strings attached. If your plan&apos;s
              not the right fit, we&apos;ll help you find one that is -- or you
              can switch for free.**
            </p>
          </ValuesItem>
          <ValuesItem className="md:u-w-[15.625rem] lg:u-w-[unset]">
            <img src="./images/icons/savings-icon.svg" alt="Savings icon" />
            <h3 className="md:u-w-9/12">Savings are a switch away</h3>
            <p className="md:u-w-[22.81rem] lg:u-text-pretty">
              Rest easy with a low, price-protected rate and no hidden fees.
            </p>
          </ValuesItem>
        </ValuesContent>
      </Values>
      <Curve variant={10} className="-u-mt-1 u-bg-[#0274d7]" />
    </>
  )
}

export default ValuesSection
