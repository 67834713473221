import React from "react"

import { cn } from "@/lib/utils"

type PhoneBannerProps = React.HTMLAttributes<HTMLDivElement>

const PhoneBanner = ({ children, className, ...props }: PhoneBannerProps) => {
    return (
        <div
            data-cy="phone-banner"
            {...props}
            className={
                cn(
                    "u-flex u-justify-center u-items-center u-gap-6 u-bg-blue-600 u-text-white u-py-4 u-px-6 u-text-5 t-title-sm lg:t-title-base u-leading-5 u-font-400 md:u-leading-6",
                    className
                )
            }
        >
            {children}
        </div>
    )
}

export default PhoneBanner
