"use client";

import { createContext, useContext } from "react";

type NavBarContextValue = {
    value: string;
    onItemSelect(itemValue: string): void;
    isRootExpanded: boolean;
    setIsRootExpanded(isExpanded: boolean): void;
};

export const NavBarContext = createContext<NavBarContextValue | undefined>(undefined);

type NavBarContextProviderProps = NavBarContextValue & {
    children: React.ReactNode;
};

export const NavBarContextProvider = ({ value, onItemSelect, isRootExpanded, setIsRootExpanded, children }: NavBarContextProviderProps) => {
    return (
        <NavBarContext.Provider
            value={{
                value,
                onItemSelect,
                isRootExpanded,
                setIsRootExpanded
            }}
        >
            {children}
        </NavBarContext.Provider>
    );
}

export const useNavBarContext = () => {
    const context = useContext(NavBarContext);

    if (!context) {
        throw new Error("useNavBarContext must be used within a NavBarContext Provider");
    }

    return context;
};
