import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export type ValuesProps = HTMLAttributes<HTMLDivElement>;

const Values = ({ className, ...props}: ValuesProps) => {
    return (
        <section
            className="u-bg-gradient-to-b-blue-blue"
        >
            <div
                data-cy="values"
                {...props}
                className={
                    cn(
                        "u-flex u-flex-col u-py-12 u-px-6 u-gap-10",
                        "lg:u-flex-row lg:u-justify-around lg:u-max-w-[89.063rem] lg:u-mx-auto lg:u-py-[7.5rem] lg:u-px-[12.25rem]",
                        className
                    )
                }
            />
        </section>
    );
};

export { Values };
