import { setCookie } from 'cookies-next'

export const LINK_DELAY_MS = 300

interface OpenLinkOptions {
  newTab?: boolean
  delayMs?: number
}

export function openExternalLink(
  link: string,
  options: OpenLinkOptions = { newTab: false, delayMs: 0 },
) {
  if (!options.delayMs) {
    window.open(link, options.newTab ? '_blank' : '_self')
    return
  }

  setTimeout(() => {
    window.open(link, options.newTab ? '_blank' : '_self')
  }, options.delayMs)
}

function setVstCookie() {
  setCookie('X-Route', 'vst', { domain: '.txu.com' })
}

export async function setCookieAndLinkOff(
  route: string,
  newTab: boolean = false,
) {
  openExternalLink(route, { newTab })
}

export async function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
  try {
    e.preventDefault()
    openExternalLink(e.currentTarget.href)
  } catch (error) {
    console.error(error)
  }
  return
}

export function linkThruString(link: string) {
  try {
    openExternalLink(link)
  } catch (error) {
    console.error(error)
  }
}
