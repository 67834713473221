import Link from 'next/link';

import Logo from '../../../../public/txu-energy-logo.svg';

const HeaderLogo = () => {
  return (
    <Link href="/" className="u-flex u-items-center" data-cy="logo">
        <Logo
            className="u-h-9 lg:u-h-[3.125rem]"
        />
    </Link>
  );
};

export default HeaderLogo;
