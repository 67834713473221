import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export type ValuesContentProps = HTMLAttributes<HTMLDivElement>;

const ValuesContent = ({ className, ...props}: ValuesContentProps) => {
    return (
        <div
            data-cy="values-content"
            {...props}
            className={
                cn(
                    "u-flex u-flex-col u-items-center u-gap-8 lg:u-gap-[3.75rem] lg:u-flex-col lg:u-items-center",
                    "md:u-flex-row md:u-items-start",
                    className
                )
            }
        />
    );
};

export { ValuesContent };
