import Link from 'next/link'

const FooterSection = () => {
  return (
    <footer className="u-flex u-flex-col u-gap-8 u-bg-blue-600 u-px-6 u-py-12 u-text-white lg:u-relative lg:u-flex-row-reverse lg:u-justify-between lg:u-gap-20 lg:u-px-[7.625rem] lg:u-pb-20 lg:u-pt-[13.75rem]">
      <div className="lg:u-max-w-[45.625rem] [&_p]:u-mb-2 [&_p]:u-text-left [&_p]:u-text-3 [&_p]:u-font-400">
        <p>
          <sup>1</sup>Claim bonus online or via phone within the time period
          specified on the redemption postcard that will be mailed to you after
          enrollment. Your $100 Visa® prepaid reward card will be sent within
          2-4 weeks after redemption requirements have been met and TXU Energy
          has received payment in full of your first month’s bill. Bonus valid
          for new TXU Energy customers enrolled through this offer on a
          qualifying plan. Customer must be in good standing, not pending
          cancellation, and remain on qualifying plan at time of reward
          processing to receive bonus. Prepaid card is valid for 6 months after
          issuance. Limit one per premise. The Reward Card is issued by The
          Bancorp Bank pursuant to license by Visa® International Incorporated
          and can be used everywhere Visa® is accepted. Visa® is a registered
          trademark.
        </p>
        <p>
          <sup>*</sup>Price Protect PromiseSM applies to residential term
          contracted customers on fixed rate products. The only circumstance in
          which the price could change during the 24-month minimum term is to
          reflect actual changes in law or regulatory charges as described in
          the Electricity Facts Label (EFL).
        </p>
        <p>
          <sup>**</sup>Total Satisfaction Guarantee -&nbsp;Just call us and
          cancel within 60 days of your sign-up date and, even if you signed a
          contract, we’ll waive any early cancellation fees. New TXU Energy
          residential customers only. Cancellation fee applies if you cancel
          after 60 days. You remain responsible for any billed and unbilled
          charges.
        </p>
      </div>
      <div className="u-flex u-flex-col u-items-center u-gap-9 lg:u-items-start lg:u-gap-7">
        <img
          src="/txu-energy-logo-white.svg"
          alt="TXU energy white logo"
          className="u-max-w-36 lg:u-absolute lg:u-top-20"
        />
        <ul className="u-flex u-flex-col u-items-center u-gap-4 lg:u-flex-row lg:u-gap-6 [&_a]:u-text-4.5 [&_a]:u-font-900">
          <li>
            <Link href="/">Home</Link>
          </li>
          <li>
            <a href="/why-txu/">Why TXU Energy</a>
          </li>
          <li>
            <Link
              href="https://www.txu.com/help/support/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="u-flex u-flex-col u-items-center u-gap-2.5 u-text-3 lg:u-items-start lg:u-gap-6">
          <ul className="u-flex u-justify-center u-gap-4">
            <li>
              <Link
                href="https://www.txu.com/privacy-notice"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <a
                href="https://www.txu.com/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <Link className="ot-sdk-show-settings" href="#">
                Cookie Settings
              </Link>
            </li>
          </ul>

          <p>
            © {new Date().getFullYear()} TXU Energy Retail Company LLC REP
            #10004
          </p>
        </div>

        <img
          className="bbb-acredited-logo"
          src="/bbb-acredited-logo.svg"
          alt="Better Business Bureau aredited logo"
        />
      </div>
    </footer>
  )
}

export default FooterSection
